import { useMutation } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { useApi } from '@netpurpose/api'
import { useModalContext } from '@netpurpose/core'
import { LinkText, Modal, Text } from '@netpurpose/np-ui'
import { ModalActionTrigger } from '#components/portfolio/portfolioTypes'
import { UploadHelperContent } from '#components/portfolio/UploadHelperAction'
import { useAnalytics } from '#context/analytics'
import { useTasks } from '#context/tasks'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import CreatePortfolioForm from './CreatePortfolioForm'

export const CreatePortfolioContent = () => {
  const { createPortfolio } = useTasks()
  const { api } = useApi()
  const { closeModal: closeActualModal } = useModalContext()
  const closeModal = () => closeActualModal('createPortfolio')
  const [showUploadHelper, setShowUploadHelper] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutate: submitForm, isPending } = useMutation({
    mutationFn: api.portfolio.upload,
    onSuccess: (token, portfolio) => {
      createPortfolio(token, portfolio)
      closeModal()
    },
    onError: (err) => setErrorMessage(err.message),
  })

  const analytics = useAnalytics()

  const onDownloadTemplate = () =>
    analytics?.logEvent(AnalyticsEventName.ButtonClick, {
      button_name: AnalyticsButtonName.DownloadPortfolioTemplate,
      location: 'create_portfolio_form',
    })

  if (showUploadHelper) {
    return <UploadHelperContent />
  }

  return (
    <>
      <Modal.ContentContainer>
        <Modal.HeaderText>Create your portfolio.</Modal.HeaderText>
        <Text>
          Upload your set of holdings in CSV. Make sure you&apos;ve got ISIN codes. If you
          don&apos;t want to share your fund size, just put $1M here. Not sure how to upload a
          portfolio? <LinkText onClick={() => setShowUploadHelper(true)}>Learn more</LinkText>
        </Text>
      </Modal.ContentContainer>
      <CreatePortfolioForm
        onSubmit={(formValues, mutateOptions) => {
          setErrorMessage(null)
          submitForm(formValues, mutateOptions)
        }}
        onDownloadTemplate={onDownloadTemplate}
        errorMessage={errorMessage}
        onCancel={closeModal}
        isSubmitting={isPending}
      />
    </>
  )
}

export const CreatePortfolioModal: FC = () => {
  const { isModalOpen, closeModal } = useModalContext()
  return (
    <Modal
      open={isModalOpen.createPortfolio}
      title="Create portfolio"
      onClose={() => closeModal('createPortfolio')}
    >
      <CreatePortfolioContent />
    </Modal>
  )
}

type Props = {
  renderAction: FC<ModalActionTrigger>
}

export const CreatePortfolioAction: FC<Props> = ({ renderAction: Action }) => {
  const { openModal } = useModalContext()

  return <Action onClick={() => openModal('createPortfolio')} />
}
